<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      {{ userData }}
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'admin-company-list' }">
          Company list
        </b-link>
        for other companies.
      </div>
    </b-alert>

    <b-card no-body class="mb-0" v-if="userData">
      <!-- <b-card-header class="pt-3 d-flex justify-content-center">
      <h2>{{ $t('add_employee') }}</h2>
    </b-card-header> -->
      <b-row class="m-2 pt-2">
        <b-col cols="12" md="3">
          <div class="d-flex flex-column align-items-center">
            <b-avatar
              rounded="bottom"
              variant="light-dark"
              size="168"
              ref="previewEl"
              :src="userData.logo"
            />

            <b-row>
              <router-link
                :to="{
                  name: 'admin-edit-company',
                  params: { id: userData._id },
                }"
              >
                <b-button variant="primary" type="submit" class="mt-1">
                  {{ $t("edit_data") }}
                </b-button>
              </router-link>
              <router-link
                :to="{
                  name: 'employee',
                  query: { company: userData._id },
                }"
              >
                <b-button variant="warning" type="submit" class="mt-1 ml-1">
                  {{ $t("employees.view") }}
                </b-button>
              </router-link>
            </b-row>
          </div>
        </b-col>
        <b-col cols="12" md="8">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("company_name") }}</span>
              </th>
              <td class="pb-50">
                {{ userData.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("mobile_no") }}</span>
              </th>
              <td class="pb-50">
                {{ userData.mobileNo }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="MailIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("email") }}</span>
              </th>
              <td class="pb-50">
                {{ userData.email }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("username") }}</span>
              </th>
              <td class="pb-50">
                {{ userData.username }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="MapPinIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("address") }}</span>
              </th>
              <td class="pb-50">
                {{ userData.address }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon icon="FileTextIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t("upload_commercial")
                }}</span>
              </th>
              <td class="pb-50 text-capitalize">
                <b-img
                  :src="require('@/assets/images/icons/pdf.png')"
                  height="auto"
                  width="14"
                  class="mr-1"
                />

                <a
                  :href="`${$apiURL}/files/${
                    userData.commercialFile
                      ? userData.commercialFile.fileName
                      : ''
                  }`"
                  class="align-bottom"
                  target="_blank"
                >
                  {{
                    userData.commercialFile
                      ? userData.commercialFile.fileName
                      : ""
                  }}
                </a>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormGroup,
  BAvatar,
  BCardHeader,
  BForm,
  BFormFile,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import router from "@/router";
import { companyService } from "@/services/company";
import { constructUrlImage } from "@/libs/helper";
export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BAvatar,
    BForm,
    BFormFile,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    vSelect,
  },
  setup() {
    const userData = ref(null);
    const apiUrl = process.env.VUE_APP_API_URL;
    const id = router.currentRoute.params.id;

    companyService
      .getCompany(id)
      .then((response) => {
        if (response.status) {
          userData.value = response.data;
          userData.value.logo = constructUrlImage(response.data.logo);
        }
      })
      .catch((error) => {
        console.log(error);
        userData.value = undefined;
      });
    return {
      userData,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
